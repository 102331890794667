import React from 'react';
import "../styles/contact-form.scss";

const FormInputBox = (props) => <input type="text" placeholder={props.prompt} name={props.name} onChange={props.onChange} value={props.value} />;

const FormTextBox = (props) => <textarea placeholder={props.prompt} rows="10" name={props.name} onChange={props.onChange} />;

export const PrimaryButton = (props) => <input type="submit" className="primary-button" value={props.title} />;

export const PrimaryLink = (props) => <input type="button" className="primary-button" value={props.title} onClick={props.onClick} />;

export default class Contact extends React.Component {
    state = {
        name: undefined,
        email: undefined,
        subject: undefined,
        message: undefined
    }

    isValidEmail() {
        return this.state.email &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    }

    isValid = () => {
        return (this.state.name && this.state.email && this.state.message && true && this.isValidEmail()) || false
    }

    handleChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState({
            [name]: value,
        })
    }

    submit = (event) => {
        event.preventDefault();
        if (this.isValid()) {
            document.getElementById("contact-form").submit();
        } else {
            if(!this.isValidEmail()) {
                alert("Email address seems invalid.Please check and click 'Get In Touch' again.")
            } else alert("Few required fields are not filled in. Please fill and click 'Get In Touch' again!")
        }
    }


    render = () => {
        console.log(this.props)
        return (
        <div className="form">
            <form id="contact-form" onSubmit={this.submit} action="https://api.formcubes.com/reg/v1/s/reg_3qpcn10kbqvdvm4" method="POST">
                <FormInputBox prompt="Full Name*" name="name" onChange={this.handleChange} />
                <FormInputBox prompt="Email*" name="email" onChange={this.handleChange} />
                <FormInputBox prompt="Subject" name="subject" onChange={this.handleChange} value={this.props.subject} />
                <FormTextBox prompt="Write a note.*" name="message" onChange={this.handleChange} />
                <PrimaryButton title="Get In Touch" />
            </form>
        </div>
    )}
}